import cat from './cat.gif';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={cat}/>
                <p>
                    I'll put a website up here eventually
                </p>
                <p>
                    Meep!
                </p>
            </header>
        </div>
    );
}

export default App;
